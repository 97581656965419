@use "../../../../assets/scss/colors";
@use "../../../../assets/scss/typography";

.profile-modal-msg {
  word-wrap: break-word;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: var(--k4n-secondary-color);
  margin: 0;
  margin-top: 38px;
}

.pm-page-container {
  background-color: #e5e5e5;
  width: 100vw;
  min-height: 100vh;
}

.pm-management-container {
  width: 100%;
  display: flex;
  height: auto;
  padding: 50px;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    padding: 20px;
  }

  .pm-management-content {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 50px;

    .pm-buttons-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      gap: 20px;
      margin-left: auto;
      max-height: max-content;

      .pm-back-btn {
        padding: 10px 25px;
        background: transparent;
        border: 1px solid #ddd;
        color: #000;
        white-space: nowrap;
        border-radius: 8px;

        &:hover {
          background: #f2f2f2;
          color: #333;
          cursor: pointer;
        }

        @media screen and (max-width: 768px) {
          margin-left: auto;
          margin-top: 20px;
          float: right;
        }
      }

      .pm-save-btn {
        padding: 10px 25px;
        background: var(--k4n-secondary-color);
        border-radius: 8px;
        border: none;
        color: var(--k4n-quaternary-color);
        width: fit-content;

        &:hover {
          cursor: pointer;
        }

        @media screen and (max-width: 768px) {
          margin-left: auto;
          margin-top: 20px;
          float: right;
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding: 20px;
    }

    .pm-management-header {
      width: 100%;
      background: var(--k4n-secondary-light-color);
      border-radius: 16px;
      height: 77px;
      display: flex;
      align-items: center;
      padding: 0 32px;
      justify-content: space-between;
      margin: 0px 0 20px 0;

      @media screen and (max-width: 768px) {
        height: 44px;
      }
      h1 {
        font-family: typography.$font-bold;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: var(--k4n-quaternary-color);
        display: inline-block;

        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }
    }

    .description {
      font-family: typography.$font-main;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #737373;
      margin-left: 5px;
    }

    .pm-checkbox {
      display: flex;
      width: 25px;
      height: 25px;
      /* Hide the default checkbox */
      input[type="checkbox"] {
        display: none;
      }

      /* Create a custom checkbox appearance */
      label {
        position: relative;
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
      }

      /* Custom checkbox */
      label::before {
        content: "";
        position: absolute;
        width: 25px;
        height: 25px;
        background-color: #fff;
        border: 2px solid var(--k4n-secondary-color);
        border-radius: 5px;
        transition: all 0.3s;
      }

      /* When the checkbox is checked */
      input[type="checkbox"]:checked + label::before {
        background-color: var(
          --k4n-secondary-color
        ); /* Change the background color */
        background-image: url("../../../../assets/images/check.svg");
        background-size: 12px 12px;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .profile-grants {
      margin-top: 20px;
      .grants-header {
        display: flex;
        align-items: center;
        flex-direction: row;
        border-bottom: 1px solid #dadada;
        padding-bottom: 11px;
        h2 {
          font-family: typography.$font-bold;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          color: var(--k4n-quaternary-color);
          display: inline-block;
        }

        span {
          font-family: typography.$font-main;
          font-style: normal;
          font-weight: 400;
          color: var(--k4n-quaternary-color);
          margin-left: auto;
          margin-right: 16px;
        }
      }
      .pm-arrow-down {
        position: relative;
        width: 16px;
        height: 16px;
        cursor: pointer;
      }

      .active-arrow {
        transform: rotate(180deg);
      }

      .grant-category-header {
        display: flex;
        align-items: center;
        padding: 10px 0px 10px 0px;
        h5 {
          font-family: typography.$font-bold;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          margin-left: 16px;
          color: var(--k4n-quaternary-color);
          display: inline-block;
          margin-bottom: 0;
        }
      }
      .grant-group {
        .group-name {
          display: flex;
          align-items: center;
          margin-top: 10px;
          h3 {
            font-family: typography.$font-bold;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            color: var(--k4n-secondary-color);
            margin-left: 16px;
            display: inline-block;
          }
        }

        .grant-category {
          border-bottom: 1px solid rgba(218, 218, 218, 0.5);

          .grants-list {
            .grant {
              display: flex;
              align-items: center;
              padding: 10px 0px 10px 0px;
              span {
                font-family: typography.$font-main;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: var(--k4n-quaternary-color);
                margin-left: 10px;
              }
            }
          }
        }
      }
    }

    .pm-management-date-div {
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .options {
      border-bottom: none;
      width: 100%;
      height: max-content;
      display: flex;
      padding-bottom: 11px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        height: auto;
        border-bottom: none;
      }
      .pm-input-container {
        width: max-content;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        .pm-input {
          width: 315px;
          height: 55px;
          border: 2px solid colors.$primary-white-background;
          background: transparent;
          border-radius: 16px;
          color: colors.$complementary-black;
          padding-left: 23px;
          font-family: typography.$font-main;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
          @media screen and (min-width: 769px) and (max-width: 1024px) {
            width: 221px;
          }
        }
        .pm-input:focus {
          color: colors.$complementary-black;
          border-radius: 16px;

          label {
            display: block !important;
          }
        }
        .pm-input:focus + label {
          label {
            display: block !important;
          }
        }
        .pm-input::placeholder {
          font-family: typography.$font-main;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          color: #737373;
          flex: none;
          order: 0;
          flex-grow: 0;
        }
      }
      .pm-input-label {
        font-family: typography.$font-main;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
        color: #737373;
        position: absolute;
        padding-left: 26px;
        padding-top: 3px;
        display: none;
      }
      .pm-show-input {
        display: block !important;
      }
      .pm-select-container {
        margin-left: 35px;

        @media screen and (max-width: 768px) {
          margin-left: 0;
          margin-top: 20px;
        }

        .pm-select {
          width: 221px;
          height: 55px;
          border: 2px solid colors.$primary-white-background;
          background: transparent;
          border-radius: 16px;
          color: colors.$complementary-black;
          padding-left: 23px;
          padding-top: 5px;
          background-image: url(../../../../assets/images/arrow_yellow_down.svg);
          background-repeat: no-repeat;
          background-position: calc(100% - 14px) center;
          appearance: none;
          text-indent: 1px;
          cursor: pointer;

          option {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        .pm-select:focus-visible {
          outline: none;
        }

        .pm-select-value-txt {
          font-family: typography.$font-main;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          color: colors.$complementary-black;
        }
      }

      .pm-error-msg {
        font-family: typography.$font-main;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #ed6465;
        text-align: left;
        padding-left: 5px;
        padding-top: 5px;
        white-space: nowrap;
      }
    }

    .options-btn-mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .option-all {
      background: #ffffff;
      border: #ffffff;
      margin-right: 20px;
      height: 40px;
    }
    .option-all:hover {
      background-color: var(--k4n-secondary-color);
      border-radius: 12px;
    }
    .pm-management-option-btn {
      background-color: var(--k4n-secondary-color);
      font-family: typography.$font-bold;
      font-size: 14px;
      line-height: 120%;
      color: var(--k4n-quaternary-color);
      box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
      border-radius: 12px;
      border-width: 0;
      padding: 11.5px 16px;
      margin-right: 10px;
      width: fit-content;
      height: 45px;
    }

    .pm-management-option-btn-disabled {
      background-color: transparent;
      font-family: typography.$font-bold;
      font-size: 14px;
      line-height: 120%;
      color: #737373;
      border-radius: 12px;
      border-width: 0;
      padding: 11.5px 16px;
      margin-right: 10px;
      width: fit-content;
      height: 45px;
    }

    .pm-management-option-btn-disabled:hover {
      background-color: var(--k4n-secondary-color);
      color: var(--k4n-quaternary-color);
    }
    .date {
      padding-left: 10px;
      font-family: typography.$font-bold;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: var(--k4n-primary-light-color);
    }
    .pm-manager {
      width: 100%;
      margin-top: 10px;
      position: relative;
      display: flex;
      flex-direction: row;
    }
    .new-btn {
      width: max-content;
      margin-left: auto;
      button {
        white-space: nowrap;
        font-size: 14px;
        border-radius: 12px;
      }
      @media screen and (max-width: 768px) {
        margin-left: auto;
      }
    }

    .new-btn-mobile {
      button {
        width: 40px;
        height: 40px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
      }
    }

    .pm-status {
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }

    .ml-10 {
      margin-left: 10px;
    }
  }
}

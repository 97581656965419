@use "../../../../assets/scss/typography";
@use "../../../../assets/scss/colors";

.approval-container {
  display: flex;
  flex-direction: row;
  background-color: #e5e5e5;
  gap: 24px;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding: 2rem;
  color: #000;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }
}

.approval-first-container {
  display: flex;
  flex-direction: column;
  flex: 2;
  width: 60%;
  gap: 24px;
  margin: auto;
  padding: 2rem;
  color: #000;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background-color: #fff;

  @media (max-width: 1024px) {
    width: 90%;
    padding: 1.5rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem;
  }
}

.approval-management {
  .approval-management-header {
    width: 100%;
    background: var(--k4n-secondary-light-color);
    border-radius: 16px;
    height: 77px;
    display: flex;
    align-items: center;
    padding: 0 32px;
    justify-content: space-between;
    margin: 0px 0 20px 0;

    @media screen and (max-width: 768px) {
      height: 44px;
    }
    h1 {
      font-family: typography.$font-bold;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      color: var(--k4n-quaternary-color);
      display: inline-block;

      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

  p {
    color: #666;
    font-size: 14px;
    margin-bottom: 24px;
    line-height: 1.5;
  }

  .group {
    margin-top: 24px;

    &-title {
      font-size: 16px;
      margin-bottom: 16px;
    }
  }
}

.help-panel {
  flex: 1;
  max-width: 300px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 20px;

  @media (max-width: 1024px) {
    max-width: 100%;
    width: 100%;
    margin: 20px auto;
  }

  .help-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;

    h2 {
      font-size: 16px;
      font-weight: 500;
    }
    svg {
      color: #000;
    }
  }

  .help-items {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .help-item {
    display: flex;
    gap: 12px;
    padding: 12px;
    border-radius: 6px;

    &:hover {
      background: #f5f5f5;
    }

    .icon {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: #000;
      }
    }

    .content {
      h3 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 4px;
      }

      p {
        font-size: 12px;
        color: #666;
        line-height: 1.4;
      }
    }
  }
}

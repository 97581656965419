@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.2);
}

.modal-container-error {
  background-color: var(--k4n-primary-color);
  padding: 32px;
  border-radius: 28px;
  z-index: 105;
}

.modal-message {
  color: #ffffff;
  font-family: typography.$font-main;
  max-width: 700px;
}

.modal-title {
  color: var(--k4n-secondary-color);
  font-family: typography.$font-bold;
}

.flex-end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.flex-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.modal-close-button-error-modal {
  cursor: pointer;
}

.error-icon-space {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background: linear-gradient(
    226.39deg,
    rgba(255, 242, 222, 0.7) 15.37%,
    rgba(255, 242, 222, 0.19) 85.86%
  );
}

.error-exclamation-div {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  position: relative;
  top: 19px;
}

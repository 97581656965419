@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.boleto-manager-container {
  width: 100%;
  display: flex;
  height: auto;
  position: relative;
  top: 56px;
}
.email-boleto-success {
  word-wrap: break-word;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: var(--k4n-secondary-color);
  margin: 0;
  margin-top: 38px;
}

.boleto-manager-content {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  min-height: 500px;
  margin-top: 80px;
  padding-left: 24px;
  padding-right: 47px;
  padding-top: 25px;
}

.boleto-manager {
  width: 83%;
  height: 77px;
  background: var(--k4n-secondary-light-color);
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 16px;
}

.btn-new-boleto {
  width: 189px;
  height: 58px;
  background: var(--k4n-secondary-color);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--k4n-quaternary-color);
  border: none;
  justify-content: center;
}

.btn-new-boleto:hover {
  opacity: 0.9;
}

.div-new-boleto {
  padding-left: 32px;
  float: right;
  height: 77px;
}

.boleto-manager-title {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: var(--k4n-quaternary-color);
  display: inline-block;
}

.manager-input-search {
  float: right;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 66px 16px 24px;
  width: 312px;
  height: 51px;
  background: #fcfcfc;
  color: black !important;
  border-radius: 30px;
  border: none;
  background-image: url(../../../../../assets//images/search_icon.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 34px) center;
}

.manager-input-search::-webkit-input-placeholder {
  /* Edge */
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: var(--k4n-primary-color);
}

.manager-input-search:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: var(--k4n-primary-color);
}

.manager-input-search::placeholder {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: var(--k4n-primary-color);
}

.manager-info {
  height: 42px;
  width: 100%;
  margin: 0;
}

.manager-date {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  width: 285px;
  height: 40px;
  background: colors.$primary-white-background;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}

.manager-boleto-info {
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  width: fit-content;
  margin-left: 24px;
  height: 40px;
  background: colors.$primary-white-background;
  border-radius: 12px;
  flex: none;
  order: 0;
  flex-grow: 0;
  float: right;
}

.boleto-info-title {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  white-space: nowrap;
  color: var(--k4n-quaternary-color);
}

.boleto-info-value {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: var(--k4n-quaternary-color);
  white-space: nowrap;
}

.no-padding {
  padding: 0;
}

.ml-42 {
  margin-left: 42px;
}

.ml-43 {
  margin-left: 43px;
}

.mx-width-122 {
  max-width: 122px !important;
}

.mx-width-146 {
  max-width: 146px;
}

.mx-width-290 {
  max-width: 290px;
}

.max-width-fit {
  max-width: fit-content;
}

.width-fit {
  width: fit-content !important;
}

.manager-filter-icon {
  float: right;
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}

.manager-input-date {
  width: 197px;
  height: 17px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: var(--k4n-quaternary-color);
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
  background: transparent;
  border: none;
}

.manager-input-date:focus {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: var(--k4n-primary-light-color);
}

.manager-input-date::-webkit-input-placeholder {
  /* Edge */
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: var(--k4n-quaternary-color);
}

.manager-input-date:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: var(--k4n-quaternary-color);
}

.manager-input-date::placeholder {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: var(--k4n-quaternary-color);
}

.clear-x {
  cursor: pointer;
}

.boleto-manager-table {
  margin-top: 44px;
}

.bm-date-error {
  margin: 0px -20px;
}

.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
  border-bottom: 1px solid rgba(10, 2, 3, 0.1);
}

.table-column-title {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: var(--k4n-primary-color);
}

.tag-new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;
  width: 48px;
  height: 22px;
  background: #fff618;
  border-radius: 12px;
}

.tag-new-span {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  color: var(--k4n-primary-color);
}

.table-cell-center {
  display: flex;
  position: relative;
  top: 5px;
}

.table-align-middle {
  margin: 0;
  position: relative;
  top: 50%;
}

.table-arrow-down {
  position: relative;
  top: -4px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.table-cell-value {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: colors.$complementary-black;
  position: relative;
  top: 10px;
}

.table-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: colors.$complementary-black;
}

.ml-24 {
  margin-left: 24px;
}

.table-boleto-id {
  display: grid;
}

.mx-wd-145 {
  max-width: 145px;
}

.txt-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.row-span-small-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #737373;
}

.span-our-number-value {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: colors.$complementary-black;
}

.table-status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  gap: 10px;
  width: 117px;
  height: 33px;
  border-radius: 12px;
}

.table-status-span {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: colors.$complementary-black;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.table-status-pending {
  background: var(--k4n-secondary-light-color);
  width: 182px !important;
}

.table-status-pending-cancellation {
  background: #c5c5c4c4;
}

.table-status-canceled {
  background: #f1f0ef;
}

.table-status-expired {
  background: #feffbf;
}

.table-status-overdue {
  background: #ffdede;
}

.table-status-paid {
  background: #deffe5;
}

.div-dots {
  float: right;
  right: 0%;
  position: absolute;
  bottom: 2px;
}

.img-dots {
  cursor: pointer;
}

.manager-pagination {
  margin: 0 auto;
  width: fit-content;
  padding: 10px;
}

.manager-pagination .page-link {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--k4n-primary-color);
}

.manager-pagination .btn-pagination-kikkin {
  background: var(--k4n-primary-color);

  span {
    color: white;
  }
}

.manager-pagination .btn-pagination-active {
  a {
    background: var(--k4n-primary-color);
    color: white;
  }
}

.manager-filter-popup {
  position: absolute;
  width: 298px;
  right: 10px;
  height: 310px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 12px;
  z-index: 10;
  padding: 19px;
}

.boleto-filter-header {
  display: inline-flex;
  width: 100%;
}

.boleto-filter-body {
  width: 100%;
  height: auto;
  margin-top: 28px;
}

.boleto-filter-buttons {
  display: flex;
}

.ml-16 {
  margin-left: 16px;
}

.ml-8 {
  margin-left: 8px;
}

.mt-11 {
  margin-top: 11px;
}

.boleto-filter-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #737373;
  border: none;
}

.boleto-filter-btn-active {
  background: var(--k4n-primary-color);
  font-family: typography.$font-main;
  color: colors.$primary-white-background;
}

.boleto-filter-title {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  color: var(--k4n-primary-color);
}

.boleto-filter-clear {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  color: var(--k4n-primary-color);
  padding-left: 8px;
  -ms-grid-row-align: center;
  align-self: center;
  cursor: pointer;
}

.boleto-filter-apply {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  width: 173px;
  height: 35px;
  background: var(--k4n-secondary-color);
  border-radius: 24px;
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: var(--k4n-quaternary-color);
  border: none;
  margin-left: 25px;
}

.boleto-filter-apply:hover {
  opacity: 0.9;
}

.wd-50-left {
  width: 50%;
  float: left;
}

.wd-50-right {
  width: 50%;
  float: right;
}

.boleto-filter-close {
  float: right;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.dropdown-height {
  height: 42px;
}

.min-wd-180 {
  min-width: 180px;
}

.min-wd-85 {
  min-width: 85px;
}

.emptySpace {
  width: 137px;
  background-color: #ccc;
  height: 21px;
  opacity: 0.25;
  border-radius: 20px;
}

.pb-20 {
  padding-bottom: 24px;
}

.boleto-filter-actions {
  width: 100%;
  height: 35px;
  margin-top: 40px;
  display: flex;
}

.boleto-dots-drop {
  height: 100%;
  display: grid;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 24px;
}

.manager-table-popup {
  position: absolute;
  width: 220px;
  right: 0px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  z-index: 10;
}

.wd-225 {
  width: 225px !important;
}

.li-table-popup {
  border: none;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: colors.$complementary-black;
  padding-top: 16px;
  cursor: pointer;
}

.li-table-popup .active {
  z-index: 2;
  color: #fff;
  background-color: var(--k4n-primary-color);

  a {
    color: #fff !important;
  }
}

.li-table-popup:hover {
  z-index: 2;
  color: #fff;
  background-color: var(--k4n-primary-color);

  a {
    color: #fff !important;
  }
}

.row-info {
  width: 100%;
  height: 367px;
  background: #fcfcfc;
  border-radius: 16px;
  padding-top: 48px;
}

.row-info-content {
  width: 100%;
  height: 367px;
}

.row-main {
  width: 100%;
  display: contents;
}

.row-txt-bold {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: #0a0203;
}

.pl-30 {
  padding-left: 30px;
}

.collapse-payer-data {
  display: grid;
  width: 100%;
  height: 100%;
}

.mt-16 {
  margin-top: 16px;
}

.mt-8 {
  margin-top: 8px;
}

.pl-30 {
  padding-left: 30px;
}

.btn-collapse-yellow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 189px;
  height: 58px;
  background: var(--k4n-secondary-color);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: var(--k4n-primary-light-color);
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  margin-right: 32px;
  float: right;
}

.btn-collapse-yellow:hover {
  opacity: 0.9;
}

.btn-collapse-transparent {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 189px;
  height: 58px;
  border: 1px solid var(--k4n-secondary-color);
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.08));
  border-radius: 16px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin-right: 32px;
  text-decoration: none;
  background-color: var(--k4n-secondary-color);
}

.btn-collapse-transparent:hover {
  opacity: 0.8;
  color: #ffffff;
}

.col-flex {
  display: flex;
}

.fl-right {
  float: right;
}

.row-mx-50 {
  max-height: 50px;
}

.height-fit {
  height: fit-content !important;
}

.k4n-blue-hover:hover {
  a {
    background: var(--k4n-primary-color);
    color: white;
  }
}

.txt-align-top {
  vertical-align: text-top;
}

.mb-not-found {
  width: 405px;
  margin: 0 auto;
}

.mb-not-found-txt {
  font-family: typography.$font-main;
  color: black;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.mb-dropdown-spinner-div {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  position: relative;
}

.mb-save-pdf-div {
  width: 102px;
  height: 25px;
  margin: 0 auto;
  padding-top: 20px;
}

.mb-save-pdf-spinner-div {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  padding-top: 20px;
}

.mb-save-pdf-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: var(--k4n-secondary-color);
  vertical-align: middle;
  padding-left: 8px;
  cursor: pointer;
}
.mb-save-pdf-txt:hover {
  color: var(--k4n-secondary-color);
}

.mb-modal-ok-div {
  width: 312px;
  height: 56px;
  margin: 0 auto;
  margin-top: 30px;
}

.mb-modal-ok-btn {
  width: 312px;
  height: 56px;
  left: 321px;
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  border: none;
  background: var(--k4n-secondary-color);
  border-radius: 24px;
}

.email-txt-div {
  margin: 0 auto;
  font-size: 11px;
  padding-top: 25px;
  width: 96%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.download-pdf-loading {
  width: 50px;
  height: 50px;
  position: relative;
  bottom: 4px;
}

.bm-time-info {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
}

.bm-time-info-div {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.dropdown-download-spinner {
  display: inline-flex;
  margin-left: 14px;
  width: 1rem;
  height: 1rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
  color: var(--k4n-secondary-color);
}

.boleto-row-download-spinner {
  display: block;
  width: 1rem;
  height: 1rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
  color: #fff;
  margin-left: 7px;
}

.dropdown-download-a {
  color: #0a0203;
  text-decoration: none;
}

.mail-dropdown-input {
  width: 100%;
  font-family: typography.$font-main;
  font-size: 12px;
  background: transparent;
  height: 40px;
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 16px;
  padding: 0 10px;
}

.mail-dropdown-div {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: flex-end;
}

.boleto-body-transparent {
  background: transparent;
}

.boleto-row-close {
  width: 100%;
  display: flex;
  height: 34px;
  align-items: center;
  justify-content: flex-end;
  background: transparent;
  border: transparent;
  transform: translateX(-5%);
}

.yellow-spinner {
  color: var(--k4n-secondary-color);
}

@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.scanner-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.scan-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  background-color: var(--k4n-secondary-color, #3b82f6);
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--k4n-primary-color, #1e3a8a);
  }

  span {
    color: white;
    font-weight: 500;
  }
}

.scanner-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scanner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 16px;
}

.scanning-text {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  font-weight: 500;
  color: white;
}

.loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  color: white;

  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    border-top-color: #ffc107;
    animation: spin 1s ease-in-out infinite;
    margin-bottom: 8px;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 300px;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 16px;
}

.scanner-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.scan-area {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 30%;
  border: 2px dashed rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.5);

  .corner {
    position: absolute;
    width: 20px;
    height: 20px;
    border-color: #ffc107;
    border-style: solid;
    border-width: 0;
  }

  .top-left {
    top: -2px;
    left: -2px;
    border-top-width: 3px;
    border-left-width: 3px;
    border-top-left-radius: 8px;
  }

  .top-right {
    top: -2px;
    right: -2px;
    border-top-width: 3px;
    border-right-width: 3px;
    border-top-right-radius: 8px;
  }

  .bottom-left {
    bottom: -2px;
    left: -2px;
    border-bottom-width: 3px;
    border-left-width: 3px;
    border-bottom-left-radius: 8px;
  }

  .bottom-right {
    bottom: -2px;
    right: -2px;
    border-bottom-width: 3px;
    border-right-width: 3px;
    border-bottom-right-radius: 8px;
  }

  .scan-line {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ffc107;
    top: 50%;
    transform: translateY(-50%);
    animation: scan 2s linear infinite;
  }

  @keyframes scan {
    0% {
      top: 10%;
    }
    50% {
      top: 90%;
    }
    100% {
      top: 10%;
    }
  }
}

.torch-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.scan-instruction {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 6px;
}

.controls {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.control-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  border: none;
  transition: background-color 0.2s;

  &.switch-camera {
    background-color: #f8f8f8;
    color: #333;
    border: 1px solid #e0e0e0;

    &:hover {
      background-color: #efefef;
    }
  }

  &.cancel {
    background-color: #e53935;
    color: white;

    &:hover {
      background-color: #d32f2f;
    }
  }
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  overflow: hidden;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
}

.modal-header {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e7eb;
}

.modal-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #374151;
}

.close-button {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #6b7280;
}

.modal-body {
  padding: 16px;
  max-height: 60vh;
  overflow-y: auto;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid #e5e7eb;
  gap: 8px;
}

.button {
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  font-size: 14px;
  transition: background-color 0.2s;
}

.button.primary {
  background: linear-gradient(to right, #1e3a8a, #3b82f6);
  color: white;
}

.button.secondary {
  background-color: #f3f4f6;
  color: #374151;
}

.button:hover {
  filter: brightness(1.1);
}

// Media queries for responsive design
@media screen and (max-width: 768px) {
  .scan-area {
    width: 80%;
    height: 20%;
  }

  .scanner-content {
    padding: 8px;
  }

  .video-container {
    height: 50vh;
  }

  .control-button {
    padding: 6px 12px;
    font-size: 14px;
  }
}

// Landscape mode adjustments
@media screen and (orientation: landscape) {
  .scanner-content {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;

    .scanning-text {
      width: 100%;
      justify-content: center;
    }

    .video-container {
      max-width: 70%;
      height: 70vh;
    }
  }
}

@use "../../../../../../assets/scss/typography";
@use "../../../../../../assets/scss/colors";

.payment-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 16px;
  background: #fff;

  &-header {
    padding: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    .icon {
      margin-right: 12px;
      color: #000;
    }

    .title {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
    }

    .config-count {
      color: #666;
      font-size: 14px;
    }

    .chevron {
      margin-left: 12px;
      transition: transform 0.2s;
      color: #000;
      &.open {
        transform: rotate(180deg);
      }
    }
  }

  &-content {
    border-top: 1px solid #e0e0e0;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;

    &.open {
      max-height: 500px;
    }
  }
}

.payment-card-content {
  border-top: 1px solid #e0e0e0;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;

  &.open {
    max-height: 300px;
    overflow-y: auto;
  }
}

.config-item {
  padding: 16px;
  border-bottom: 1px solid #d6d5d5;
  display: flex;
  align-items: center;
  background: none;
  color: #000;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: #f5f5f5;
  }

  .config-info {
    flex: 1;

    .config-description {
      font-size: 12px;
      color: #666;
    }
  }

  .config-actions {
    display: flex;
    gap: 8px;

    button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 4px;
      color: #000;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.icon-delete-approval-config svg path {
  fill: var(--k4n-secondary-light-color) !important;
}

.icon-delete-approval-config svg {
  height: 18px;
  width: 18px;
}

.icon-edit-approval-config svg path {
  fill: var(--k4n-secondary-light-color) !important;
}

.icon-edit-approval-config svg {
  height: 20px;
  width: 20px;
}

.add-config {
  padding: 16px;
  color: #000;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  width: 100%;

  &:hover {
    background: #f5f5f5;
  }

  .plus-icon {
    margin-right: 8px;
    color: #000;
  }
}

.approvals-spinner {
  margin: 0 auto;
  display: block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
  color: var(--k4n-secondary-light-color);
}

@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  max-width: 90%;
  margin: 0 auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.2);
}

.modal-container-success {
  background-color: var(--k4n-primary-color);
  padding: 32px;
  border-radius: 28px;
  z-index: 105;
}

.modal-message {
  color: #ffffff;
  font-family: typography.$font-main;
  max-width: 700px;
}

.modal-title {
  color: var(--k4n-secondary-color);
  font-family: typography.$font-bold;
}

.flex-end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.flex-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.modal-close-button-sucess-modal {
  cursor: pointer;
}

.success-vector {
  width: 296.73px;
  height: 106px;
}

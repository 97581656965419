@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.ac-mang-bg-gray {
  background-color: #f7f7f7;
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.ac-mang-manage-container {
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 30px;
  max-width: 800px;
  width: 100%;
}

.k4n-input-label {
  margin: 10px 0 5px;
  color: var(--k4n-secondary-color);
}

.k4n-input {
  border-radius: 18px;
  border: var(--k4n-secondary-color) 1px solid;
  padding: 10px 20px;
  color: #333;
  flex: 1;
}

.k4n-input:disabled {
  opacity: 0.7;
  background-color: #ddd;
}

.ac-mang-manage-title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--k4n-primary-color);
  text-align: center;
  margin-bottom: 20px;
}

.ac-mang-section-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--k4n-primary-color);
  margin-bottom: 10px;
  border-bottom: 2px solid var(--k4n-secondary-color);
  padding-bottom: 5px;
}

.ac-mang-card {
  background: #fff;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.ac-mang-info-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.ac-mang-info-group .ac-mang-label-text {
  font-size: 1rem;
  font-weight: bold;
  color: var(--k4n-secondary-color);
  margin-bottom: 5px;
}

.ac-mang-info-group .ac-mang-input-text,
.ac-mang-info-group .ac-mang-input-text {
  font-size: 1rem;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  color: var(--k4n-quaternary-color);
}

.ac-mang-info-group .ac-mang-input-text:disabled {
  background-color: #e0e0e0;
  color: var(--k4n-quaternary-color);
  cursor: not-allowed;
}

.ac-mang-yellow-link {
  color: var(--k4n-secondary-color);
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

.ac-mang-yellow-link:hover {
  text-decoration: underline;
}

.mg-left-none {
  margin-left: 0px !important;
}

.button-primary {
  padding: 10px 25px;
  background: var(--k4n-secondary-color);
  border-radius: 18px;
  border: none;
  text-align: right;
  color: var(--k4n-quaternary-color);
  font-family: typography.$font-bold;
  width: fit-content;
}

.button-primary:hover {
  opacity: 0.8;
}

.button-primary:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  width: fit-content;
}

.button-primary-outlined {
  padding: 10px 25px;
  background: #ffffff00;
  border-radius: 18px;
  border: 1px solid var(--k4n-secondary-color);
  text-align: right;
  color: var(--k4n-secondary-color);
  font-family: 12px;
  width: fit-content;
}

.button-primary-outlined:hover {
  opacity: 0.8;
}

.button-primary-outlined:disabled {
  opacity: 0.8;
  cursor: not-allowed;
  width: fit-content;
}

.ac-mang-button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.ac-mang-button-primary {
  padding: 12px 24px;
  background: var(--k4n-secondary-color);
  border-radius: 18px;
  border: none;
  color: var(--k4n-quaternary-color);
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.ac-mang-button-primary:hover {
  opacity: 0.85;
}

.ac-mang-button-secondary {
  padding: 12px 24px;
  background: none;
  border: 2px solid var(--k4n-secondary-color);
  border-radius: 18px;
  color: var(--k4n-secondary-color);
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.ac-mang-button-secondary:hover {
  background: var(--k4n-secondary-color);
  color: var(--k4n-quaternary-color);
}

.ac-mang-input-invalid {
  border-color: red !important;
}

.ac-mang-input-error {
  color: red;
  font-size: 0.875rem;
  margin-top: 5px;
}

.ac-mang-save-spinner {
  width: 1.5rem;
  height: 1.5rem;
  border: 3px solid #fff;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  display: inline-block;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* 📱 Responsividade */
@media screen and (max-width: 768px) {
  .ac-mang-manage-container {
    padding: 20px;
  }

  .ac-mang-button-group {
    flex-direction: column;
    gap: 10px;
  }

  .button-primary,
  .ac-mang-button-secondary {
    width: 100%;
    text-align: center;
  }
}

@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.newuser-container {
  background-color: #e5e5e5;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  @media (min-width: 768px) {
    padding: 2rem;
  }
}

.user-management {
  position: relative;

  .form {
    background: #ffffff;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @media (min-width: 768px) {
      padding: 2rem;
    }
  }

  .form-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      font-family: typography.$font-bold;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      margin: 0;
      color: var(--k4n-quaternary-color);
      display: inline-block;
      padding-bottom: 3rem;

      width: 100%;
      background: var(--k4n-secondary-light-color);
      border-radius: 16px;
      height: 77px;
      display: flex;
      align-items: center;
      padding: 0 32px;
      justify-content: space-between;

      @media (min-width: 768px) {
        font-size: 24px;
      }
    }

    .step-indicator {
      display: flex;

      .step {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #ddd;
        transition: all 0.3s ease;
        margin: 0.1rem;

        &.active {
          background-color: #fbb040;
          border-color: #fbb040;
        }
      }
    }
  }

  .header-divider {
    width: 100%;
    height: 1px;
    background-color: black;
  }

  .form {
    background: #ffffff;
    width: 90%;
    margin: auto 0;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .title {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: #000;
  }

  .subtitle {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #000;
  }

  .description {
    color: #000;
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
    line-height: 1.5;
  }

  .form-row {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem;
    }
    margin-bottom: 1.5rem;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .label {
      font-size: 0.875rem;
      color: #000;
    }

    .input {
      padding: 0.75rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 0.875rem;
      color: #000;

      &::placeholder {
        color: #666;
      }

      &:focus {
        outline: none;
        border-color: #666;
      }
    }
  }

  .group-section {
    margin-top: 2rem;

    .search-group {
      position: relative;

      .search-input {
        width: 100%;
        border: 1px solid #ddd;
        font-size: 0.875rem;
        color: #000;
        width: 100%;
        padding: 16px 66px 16px 24px;
        background: #fcfcfc;
        border-radius: 30px;
        background-image: url(../../../../../assets//images/search_icon.svg);
        background-repeat: no-repeat;
        background-position: calc(100% - 34px) center;
      }

      .search-input::-webkit-input-placeholder {
        /* Edge */
        font-family: typography.$font-bold;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        color: var(--k4n-primary-color);
      }

      .search-input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-family: typography.$font-bold;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        color: var(--k4n-primary-color);
      }

      .search-input::placeholder {
        font-family: typography.$font-bold;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        color: var(--k4n-primary-color);
      }
    }
  }

  .company-section {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .company-name {
      font-weight: 500;
      color: #000;
    }

    .pagination {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .page-text {
        color: #000;
        font-size: 0.875rem;
      }

      .page-input {
        width: 3rem;
        padding: 0.25rem;
        text-align: center;
        border: 1px solid #ddd;
        border-radius: 4px;
        color: #000;
      }
    }
  }

  .company-list {
    margin-top: 1rem;

    .company-item {
      padding: 1rem 0;
      width: 100%;
      border-bottom: 1px solid #ddd;

      .company-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        .company-name {
          font-weight: 500;
          color: #000;
        }
      }

      .list-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .showing-dropdown {
          .showing-select {
            padding: 0.5rem;
            border: 1px solid #ddd;
            border-radius: 4px;
            color: #000;
            background-color: white;
            cursor: pointer;
            font-size: 0.875rem;

            &:focus {
              outline: none;
              border-color: #666;
            }
          }
        }
      }
    }
  }

  .form-actions {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
    margin-top: 2rem;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    .btn-back,
    .continue-btn {
      width: 100%;

      @media (min-width: 768px) {
        width: auto;
      }

      button {
        width: 100%;
        padding: 0.75rem 2rem;
        font-size: 14px;
        border-radius: 12px;
        font-weight: 500;
        cursor: pointer;
        transition: opacity 0.2s;

        &:hover {
          opacity: 0.9;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    .btn-back {
      button {
        background: transparent;
        border: 1px solid #ddd;
        color: #000;
      }
    }

    .continue-btn {
      margin-top: 1rem;

      @media (min-width: 768px) {
        margin-top: 0;
        margin-left: 1rem;
      }
    }
  }
}

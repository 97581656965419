@use "../../../../assets/scss/colors";
@use "../../../../assets/scss/typography";

.approvals-wrapper {
  width: 100%;
  height: 100%;
  margin: 0;
  display: inline-block;
  margin-bottom: 40px;

  .approvals-header-mobile {
    padding: 10% 5% 3%;
  }

  .approvals-header-mobile-btn {
    background-color: transparent;
    border: transparent;
    float: right;
    height: 15.02px;
  }

  .approvals-header-h1-mobile {
    font-family: typography.$font-main;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: var(--k4n-secondary-color);
    margin-top: 80px;
  }

  .f-l {
    float: left;
  }

  .approvals-header-container {
    display: flex;
    width: 100%;
    height: auto;
    position: relative;
    top: 56px;

    .approvals-header {
      width: 83%;
      height: 77px;
      background: var(--k4n-secondary-light-color);
      padding-left: 32px;
      padding-right: 32px;
      border-radius: 16px;
      display: flex;
      align-items: center;
    }
    .ap-header-btn-container {
      padding-left: 32px;
      float: right;
      height: 77px;
      display: flex;
      align-items: center;
      .ap-header-btn {
        white-space: nowrap;
        font-size: 14px;
        border-radius: 16px;
        height: 58px;
        font-weight: 700;
        font-size: 18px;
      }
    }
  }

  .approvals-title {
    font-family: typography.$font-bold;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: var(--k4n-quaternary-color);
    display: inline-block;
  }

  .approvals-container {
    width: 100%;
    display: flex;
    height: auto;
    position: relative;
    top: 80px;
    height: max-content;
    margin-bottom: 100px;
  }

  .approvals-content {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 50px;
  }

  .btn-approvals-management {
    width: 189px;
    height: auto;
    background: transparent;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    font-family: typography.$font-bold;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--k4n-quaternary-color);
    border: 1px solid var(--k4n-quaternary-color);
    justify-content: center;
    margin-bottom: auto;
    padding: 5px;

    &:hover {
      background-color: #f3bd6b;
      opacity: 0.7;
      transition: background-color 0.3s, opacity 0.3s;
    }
  }

  .tabs {
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #e5e7eb;
    padding-bottom: 8px;
  }

  .tab-item {
    font-size: 16px;
    font-weight: 600;
    color: #6b7280;
    padding: 8px 16px;
    background: transparent;
    border: none;
    cursor: pointer;

    &.active {
      color: var(--k4n-secondary-color);
      border-bottom: 2px solid var(--k4n-secondary-color);
    }
  }

  .description {
    color: #6b7280;
    margin-bottom: 24px;
  }

  .filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .search-container {
    position: relative;
    width: 300px;

    .search-icon {
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      color: #9ca3af;
    }

    .search-input {
      width: 100%;
      padding: 8px 12px 8px 40px;
      border: 1px solid #e5e7eb;
      border-radius: 8px;
      font-size: 14px;

      &::placeholder {
        color: #9ca3af;
      }
    }
  }

  .filters-right {
    display: flex;
    gap: 12px;
    align-items: center;

    .filter-button,
    .accounts-button {
      padding: 8px 16px;
      border-radius: 8px;
      font-size: 14px;
    }

    .date-filter {
      background: var(--k4n-quaternary-color);
      color: white;
      padding: 8px 16px;
      border-radius: 8px;
      font-size: 14px;
    }
  }

  .summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .summary-left {
    display: flex;
    align-items: center;
    gap: 16px;

    .select-all {
      border-radius: 8px;
      font-size: 14px;
    }

    .totals {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    .total-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      color: #374151;
      font-size: 14px;

      .label {
        color: #6b7280;
      }

      .value {
        font-weight: 600;
      }
    }
  }

  .action-buttons {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    .btn-reject,
    .btn-approve {
      padding: 8px 16px;
      border-radius: 8px;
      font-size: 14px;
      flex-shrink: 0;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .btn-reject {
      border: 1px solid #e5e7eb;
      background: white;
    }

    .btn-reject:hover {
      background-color: #f3f4f6;
    }
  }
  .transactions-table-container {
    overflow-x: auto;
    .transactions-table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      margin-bottom: 2rem;

      th,
      td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #e5e7eb;
      }

      .checkbox-column {
        width: 48px;
        padding-left: 16px;

        input[type="checkbox"] {
          display: none;
        }

        /* Create a custom checkbox appearance */
        label {
          position: relative;
          cursor: pointer;
          display: inline-block;
          font-size: 16px;
        }

        /* When the checkbox is checked */
        input[type="checkbox"]:checked + label::before {
          background-color: var(
            --k4n-secondary-color
          ); /* Change the background color */
          background-image: url("../../../../assets/images/check.svg");
          background-size: 8px 8px;
          background-position: center;
          background-repeat: no-repeat;
        }

        .cb-container {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          /* Custom checkbox */
          label::before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            background-color: #fff;
            border: 2px solid var(--k4n-secondary-color);
            border-radius: 5px;
            transition: all 0.3s;
          }
        }

        .cb-all-container {
          width: 25px;
          height: 25px;
          display: flex;

          /* Custom checkbox */
          label::before {
            content: "";
            position: absolute;
            width: 25px;
            height: 25px;
            background-color: #fff;
            border: 2px solid var(--k4n-secondary-color);
            border-radius: 5px;
            transition: all 0.3s;
          }
        }
      }

      .column-header {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #374151;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;

        &:hover {
          color: var(--k4n-quaternary-color);
        }
      }

      .account-column {
        color: #111827;
        font-weight: 400;
      }

      .date-column {
        color: #111827;
        font-weight: 400;
      }

      .amount-column {
        color: #111827;
        font-weight: 400;
      }

      .arrow-column {
        width: 48px;
        text-align: right;
        padding-right: 16px;
      }

      .transaction-info {
        display: flex;
        flex-direction: column;
        gap: 2px;

        .primary-info {
          color: #111827;
          font-weight: 500;
          font-size: 14px;
          padding: 2px 8px;
          border-radius: 4px;
        }

        .secondary-info {
          font-size: 12px;
          color: #6b7280;
          border-radius: 4px;
          padding: 2px 8px;
        }
      }

      td {
        padding: 16px 12px;
        color: #111827;
        font-size: 14px;
      }

      .status-badge {
        background: #fff5e9;
        color: #92400e;
        padding: 2px 8px;
        border-radius: 4px;
        font-size: 12px;
        white-space: nowrap;
        display: inline-block;
        font-weight: 500;
      }

      .approval-warning-icon {
        background: transparent;
        border: none;
        font-size: 16px;
        color: #6b7280;
        cursor: pointer;
        padding: 4px;
        border-radius: 4px;
        transition: background-color 0.2s;
        line-height: 1;

        &:hover {
          background-color: #f3f4f6;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .approvals-wrapper {
    .approvals-content {
      padding: 10px;
    }
    .tabs {
      flex-wrap: nowrap;
      gap: 8px;
      overflow-x: auto;
    }
    .tab-item {
      font-size: 14px;
      padding: 4px 8px;
    }
    .tabs {
      flex-wrap: wrap;
      gap: 12px;
    }
    .summary-left {
      flex-wrap: wrap;
      gap: 12px;
    }
    .action-buttons {
      flex-direction: column;
      align-items: stretch;
      gap: 12px;
    }
    .search-container {
      width: 100%;
      margin-bottom: 16px;
    }
    .filters {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
    .select-all {
      width: 100%;
      max-width: 200px;
    }
  }
}

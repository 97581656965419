@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.extrato-container {
  width: 100%;
  display: flex;
  height: auto;
}
.extrato-filter-close-btn {
  width: 18px;
  height: 18px;
  outline: none;
  border: none;
  color: var(--k4n-secondary-color);
  background-color: var(--k4n-secondary-color);
  mask-size: cover;
  -webkit-mask-image: url("../../../../../assets/images/close.svg");
  mask-image: url("../../../../../assets/images/close.svg");
}
.extrato-filter-close-div {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
}
.extrato-content {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  min-height: 1000px;
  padding: 50px;
}

.row-filters-extrato {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.extrato-date-div {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.extrato-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: var(--k4n-quaternary-color);
  top: 50px;
}

.extrato-date-text {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #0a0203;
}
.options {
  border-bottom: 1px solid #dadada;
  width: 100%;
  height: 45px;
}
.option-all {
  background: #ffffff;
  border: #ffffff;
  margin-right: 20px;
  height: 40px;
}
.option-all:hover {
  background-color: var(--k4n-secondary-color);
  border-radius: 12px;
}
.extrato-option-btn {
  background-color: var(--k4n-secondary-color);
  font-family: typography.$font-bold;
  font-size: 14px;
  line-height: 120%;
  color: var(--k4n-quaternary-color);
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  border-radius: 12px;
  border-width: 0;
  padding: 11.5px 16px;
  margin-right: 10px;
  width: fit-content;
}

.extrato-option-btn-disabled {
  background-color: transparent;
  font-family: typography.$font-bold;
  font-size: 14px;
  line-height: 120%;
  color: #737373;
  border-radius: 12px;
  border-width: 0;
  padding: 11.5px 16px;
  margin-right: 10px;
  width: fit-content;
}

.extrato-option-btn-disabled:hover {
  background-color: var(--k4n-secondary-color);
  color: var(--k4n-quaternary-color);
}
.download-button {
  background: #ffffff;
  border: none;
  float: right;
  height: 40px;
}
.extrato-data {
  padding-top: 22px;
}
.extrato-date {
  width: auto;
  height: 77px;
  background: var(--k4n-secondary-light-color);
  border-radius: 16px;
  display: flex;
  align-items: center;
}
.extrato-calendar-icon {
  padding-left: 32px;
  float: left;
}
.date {
  padding-left: 10px;
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--k4n-primary-light-color);
}
.extrato-row {
  border-bottom: 1px solid #dadada;
  display: flex;
  padding-bottom: 10px;
  padding-top: 10px;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}
.operation {
  width: 35%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.transaction-icon {
  float: left;
}
.data-text-14-black {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #0a0203;
}
.data-text-14-grey {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  text-align: right;
  color: #737373;
  width: 40%;
}
.value-green {
  width: 20%;
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: right;
  color: green;
}
.value-red {
  width: 20%;
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: right;
  color: #ff0000;
}
.date-manager {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 70px;
  position: relative;
}
.date-button {
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 16px;
  width: 220px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.date-span {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  padding-left: 10px;
  color: #737373;
}
.arrow-icon {
  padding-right: 10px;
}
.date-dropdown-container {
  width: 100%;
  position: absolute;
  height: 100%;
  background: none;
}
.date-dropdown-menu {
  position: absolute;
  display: flex;
  width: 426px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 6px;
  padding: 57px;
  flex-direction: column;
  gap: 30px;
}
.date-dropdown-menu-title {
  font-family: typography.$font-bold;
  font-size: 20px;
  color: var(--k4n-primary-color);
}
.date-dropdown-menu-span {
  font-family: typography.$font-bold;
  font-size: 18px;
  color: #0a0203;
}
.date-dropdown-menu-buttons {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.last-day-btn {
  align-items: center;
  padding: 12px 16px;
  background: #f8f9fa;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  border-radius: 12px;
  border: none;
  margin-right: 10px;
}
.last-day-btn:hover {
  background-color: var(--k4n-primary-color);
  border-radius: 12px;
  color: #dadada;
}
.last-day-btn-active {
  align-items: center;
  padding: 12px 16px;
  background: var(--k4n-primary-color);
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  border-radius: 12px;
  border: none;
  margin-right: 10px;
}
.seven-days-btn {
  align-items: center;
  padding: 12px 16px;
  background: #f8f9fa;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  border-radius: 12px;
  border: none;
  margin-right: 10px;
}
.seven-days-btn:hover {
  background-color: var(--k4n-primary-color);
  border-radius: 12px;
}
.seven-days-btn-active {
  align-items: center;
  padding: 12px 16px;
  background: var(--k4n-primary-color);
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  border-radius: 12px;
  border: none;
  margin-right: 10px;
}
.thirty-days-btn {
  align-items: center;
  padding: 12px 16px;
  background: #f8f9fa;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  border-radius: 12px;
  border: none;
  margin-right: 10px;
  margin-top: 10px;
}
.thirty-days-btn:hover {
  background-color: var(--k4n-primary-color);
  border-radius: 12px;
}
.thirty-days-btn-active {
  align-items: center;
  padding: 12px 16px;
  background: var(--k4n-primary-color);
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  border-radius: 12px;
  border: none;
  margin-right: 10px;
  margin-top: 10px;
}
.ninety-days-btn {
  align-items: center;
  padding: 12px 16px;
  background: #f8f9fa;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  border-radius: 12px;
  border: none;
}
.ninety-days-btn:hover {
  background-color: var(--k4n-primary-color);
  border-radius: 12px;
}
.ninety-days-btn-active {
  align-items: center;
  padding: 12px 16px;
  background: var(--k4n-primary-color);
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  border-radius: 12px;
  border: none;
}
.dropdown-span {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #737373;
}
.dropdown-span:hover {
  color: #ffffff;
}
.dropdown-span-active {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}
.choose-date {
  width: 10px;
  color: #ff0000;
}
.date-dropdown-menu-span-2 {
  position: absolute;
  top: 323px;
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #0a0203;
}
.calendar-div {
  width: 90%;
  height: 50px;
  border-radius: 12px;
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  padding-left: 20px;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
}

.clear-x {
  padding-right: 17px;
}
.filter-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}
.no-transactions-message {
  color: colors.$complementary-black;
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.see-more-btn {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-spinner {
  width: 50px;
}
.schedule-row {
  border-bottom: 1px solid #dadada;
  padding-bottom: 10px;
  padding-top: 10px;
  align-items: center;
  display: flex;
  height: 60px;
  width: 40;
  justify-content: space-between;
}
.schedule-operation {
  width: 40%;
}

.grey-box {
  width: 20%;
  height: 20px;
  background-color: #808080;
  border-radius: 10px;
}

.delete-operation {
  color: #0a0203;
  display: flex;
  cursor: pointer;
}

.delete-img {
  width: 22.74px;
  height: 24.81px;
  display: flex;
}

.extrato-modal-success {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: var(--k4n-secondary-color);
  margin: 0;
  margin-top: 38px;
}

.statement-input-date {
  width: 197px;
  height: 17px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #0a0203;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
  background: transparent;
  border: none;
}

.statement-input-date:focus {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #0a0203;
}

.statement-input-date::placeholder {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #0a0203;
}

.transactions-table-container {
  overflow-x: auto;
  .transactions-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 2rem;

    th,
    td {
      padding: 12px;
      text-align: left;
      border-bottom: 1px solid #e5e7eb;
    }

    .checkbox-column {
      width: 48px;
      padding-left: 16px;

      input[type="checkbox"] {
        display: none;
      }

      /* Create a custom checkbox appearance */
      label {
        position: relative;
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
      }

      /* When the checkbox is checked */
      input[type="checkbox"]:checked + label::before {
        background-color: var(
          --k4n-secondary-color
        ); /* Change the background color */
        background-image: url("../../../../../assets/images/check.svg");
        background-size: 8px 8px;
        background-position: center;
        background-repeat: no-repeat;
      }

      .cb-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        /* Custom checkbox */
        label::before {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          background-color: #fff;
          border: 2px solid var(--k4n-secondary-color);
          border-radius: 5px;
          transition: all 0.3s;
        }
      }

      .cb-all-container {
        width: 25px;
        height: 25px;
        display: flex;

        /* Custom checkbox */
        label::before {
          content: "";
          position: absolute;
          width: 25px;
          height: 25px;
          background-color: #fff;
          border: 2px solid var(--k4n-secondary-color);
          border-radius: 5px;
          transition: all 0.3s;
        }
      }
    }

    .column-header {
      display: flex;
      align-items: center;
      gap: 4px;
      color: #374151;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        color: var(--k4n-quaternary-color);
      }
    }

    .account-column {
      color: #111827;
      font-weight: 400;
    }

    .date-column {
      color: #111827;
      font-weight: 400;
    }

    .amount-column {
      color: #111827;
      font-weight: 400;
      text-align: right;
      padding-right: 24px;
    }

    .arrow-column {
      width: 48px;
      text-align: right;
      padding-right: 16px;
    }

    .transaction-info {
      display: flex;
      flex-direction: row;
      gap: 2px;

      .primary-info {
        color: #111827;
        font-weight: 500;
        font-size: 14px;
        padding: 2px 8px;
        border-radius: 4px;
      }

      .secondary-info {
        font-size: 12px;
        color: #6b7280;
        border-radius: 4px;
        padding: 2px 8px;
      }
    }

    td {
      padding: 16px 12px;
      color: #111827;
      font-size: 14px;
    }

    .status-badge {
      background: #fff5e9;
      color: #92400e;
      padding: 2px 8px;
      border-radius: 4px;
      font-size: 12px;
      white-space: nowrap;
      display: inline-block;
      font-weight: 500;
    }

    .btn-arrow {
      background: transparent;
      border: none;
      font-size: 16px;
      color: #6b7280;
      cursor: pointer;
      padding: 4px;
      border-radius: 4px;
      transition: background-color 0.2s;
      line-height: 1;

      &:hover {
        background-color: #f3f4f6;
      }
    }
  }
}

.btn-receipts-transaction {
  background-color: transparent;
  border: transparent;
  box-shadow: transparent;
}

.spinner-receipts {
  display: block;
  width: 1rem;
  height: 1rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
  color: var(--k4n-secondary-color);
  margin-left: 7px;
}

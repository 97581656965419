@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.inputGroup {
  font-family: typography.$font-main;
  margin: 1em 0 1em 0;
  max-width: 100%;
  position: relative;
}

.inputGroup input {
  font-size: 100%;
  padding: 0.8em;
  outline: none;
  border: 2px solid var(--k4n-secondary-color);
  background-color: transparent;
  border-radius: 20px;
  width: 100%;
  color: var(colors.$complementary-black);

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
}

.inputGroup label {
  font-size: 100%;
  position: absolute;
  left: 0;
  padding: 0.8em;
  margin-left: 0.5em;
  pointer-events: none;
  transition: all 0.3s ease;
}

.inputGroup :is(input:focus, input:valid, input:disabled) ~ label {
  transform: translateY(-50%) scale(0.9);
  margin: 0em;
  margin-left: 1.3em;
  padding: 0.4em;
  background-color: white;
}

.inputGroup :is(input:focus, input:valid, input:disabled) {
  border-color: #df8703;
}
